@import "src/App";

.download-pdf-container {
  @include flex(space-between, center, column);
  //height: 10%;
  width: 80%;
  padding: 24px;
  margin: 32px auto;
  background: rgba($color-silver, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
  gap: 20px;

  .pdf-no-attachments {
    @include flex(center, center, column);
    border: solid 1.5px $color-black-8;
    max-width: 196px;
    gap: 16px;
    padding: 16px;
    text-align: center;
  }

  .pdf-attachments {
    border: solid 1.5px $color-black-8;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 8px 24px;
    gap: 10px;
    @include flex(start, center);
    width: 100%;
    position: relative;
  .pdf-attachments__svg{
    min-width: 20px;
    svg{
      width: 100%;
    }
  }
    p {
        min-width: 60%;
      overflow-wrap: break-word;
    }

    .download-pdf {
      cursor: pointer;

    }

    .pdf-exit svg {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    @media (max-width: 1600px) {
      p {
        @include more-text(1);
      }

    }
  }

  .pdf-name-container {
    margin: 0 0 32px 0;
    width: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    @include flex(start, center);
    gap: 10px;
    border: solid 1.5px $color-black-8;
    border-radius: 12px;

    P {
      padding-top: 3px;
    }

    @media (max-width: 1500px) {
      flex-direction: column;
    }
    @media (max-width: 500px) {
      gap: 0;
    }

  }

  .btn-pdf-poster {
    padding: 19px 32px 16px;
    width: 100%;
  }

  .attachments__btn {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 600px) {
  .test p {
    @include more-text(1);
    width: 90%;

  }
}