@import "../../App";

.conversation-title-main {
  width: 92%;
  padding: 16px;
  background-color: $child-titleColor;
  color: $child-titleTextColor;

  .conversation-title {
    width: 100%;
    margin: auto;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    @include flex(space-between, center);
    @include font-style($font-main, 700, 20px, 28px);
  }
}

.inbox-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: $bgColor-event;
  height: 98%;
}

.chat-tabs {
  @include flex(center, center);
  gap: 20px;
  background: $color-background;
  color: $color-text-main;
  position: relative;

  .pub-chat {
    padding-right: 20px;
  }
  .pub-chat,
  .pr-chat{
    width: 50%;
    @include font-style($font-title, 500, 16px, 20px);
  }

  .inbox-helper-mobile{
    position: absolute;
    right:calc(50% - 12.5px);
    top: 10px;
    z-index: 2;
    @media (min-width: mediaWight(mobileXl)) {
      display: none;
    }
  }
}

.conversation-main {
  width: 100%;
  height: 100%;
}

@media (max-width: mediaWight(mobileXl)) {
  .conversation-title-main {
    display: none;
  }
}
@media (max-width: mediaWight(desktopSm)) {
.inbox-main{
  height: 76vh;
}
}