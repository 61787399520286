@import "./src/App";
.exhibition-main {
  &::-webkit-scrollbar {
    width: 0;
  }
  height: inherit;
  width: 80%;
  overflow: scroll;
  margin: 0 auto;
  padding: 30px;
  @include flex(center, none);
  flex-wrap: wrap;
  gap: 20px 20px;
  box-sizing: border-box;
  &__container {
    width: 100%;
    @include flex(start, none);
    height: auto;
    min-height: 150px;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: 650px)  {
      justify-content: center;
    }
  }
  &__item {
    padding: 10px;
    box-sizing: border-box;
    width: 18%;
    min-width: 150px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &__image {
      width: 100%;
    }
    &:hover {
      box-shadow: 1px 2px 6px $color-black;
      scale: 1.1;
    }
  }
}