@import '../../App';

.livepoll-main {
    display: flex;
    gap: 5px;
    height: 100%;
}

.livepoll-inside {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .livepoll-helper-mobile{
        z-index: 5;
        top: 5px;
        right: 5px;
        position: absolute;
        @media (min-width: mediaWight(mobileXl)) {
            display: none;
        }
    }
}

.livepoll-title-main {
    width: 92%;
    padding: 12px;
    background-color: $color-background;

    .livepoll-title {
        width: 100%;
        margin: auto;
        text-align: center;
        @include flex(space-between, center);

        p {
            @include font-style($font-second, 700, 20px, 28px);
        }
    }
}

.livepoll-cards-main {
    @include flex(content, center);
    flex-direction: column;
    gap: 10px;
    overflow-y: overlay;
    height: 100%;

    .live-polls-container {
        padding-bottom: 16px;
    }
}

.livepoll-card {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 30px;

    div:first-child::after {
        content: "";
        background: $color-black-50-rgba;
        width: 1px;
        height: 100px;
        display: block;
    }

    div:first-child {
        display: flex;
        gap: 10px;
    }

    .livepoll-detail {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    img {
        border-radius: 50px;
    }
}



.search {
    padding: 12px;
    @include flex(space-around, center);
    gap: 8px;

    //.MuiOutlinedInput-root {
    //    border-radius: 999px;
    //    background-color: #f3f4f8;
    //    padding: 4px 8px;
    //}

}

.live-poll-filter {
    display: flex;
    padding: 9px;
    background-color: $bgColor-live-poll;
    border-radius: 999px;
}

.live-polls-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 12px;

    .polls-question {
        @include font-style($font-main, 700, 16px, 28px);
    }

    .polls-question-container-head {
        @include font-style($font-main, fontWeight('extraBold'), 18px, 120%)
    }


    .MuiAccordionSummary-gutters,
    .Mui-expanded{
        background-color: $bgColor-live-poll;
        border-radius: 12px;
        box-shadow: none;
        color: $color-live-poll;
    }
}

.polls-radio-group {
    margin-top: 5px;
}

.polls-answers-radio {
    @include flex(space-between, center);
    border-radius: 10px;
    padding: 10px 4px;
    //font-weight: bold;

    span {
        @include font-style($font-main, 600, 18px, 24px);
    }

    a {
        color: $color-black;
        @include font-style($font-main, 400, 14px, 24px);
    }

    @include font-style($font-main, 400, 14px, 24px);

}

.gradient {
    //background: linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(140, 25, 95, 0.9) 100%);
    background: $ratio-gradient
}


@media (min-width: 1024px) and (max-width: 1768px) {

    .live-poll-filter {
        padding: 6px;
    }

    .polls-radio-group {
        margin-top: 0;
    }

    .live-polls-container {
        gap: 16px;

        .polls-question {
            @include font-style($font-main, 700, 14px, 24px);
        }

        .polls-question-container-head {
            //color: #545E67;
            @include font-style($font-main, fontWeight('extraBold'), 18px, 120%)
        }
    }
}

//small screens
@media (max-width: 768px) {
    .title-main {
        display: none;
    }
}