@import "../../App";
$signin-page-background: #f8f8f8;

h3 {
  height: 0;
}

.sign-in-main {
  // height: 100vh;
  width: 100%;
  height: 91vh;
  display: flex;
  background-color: $signin-page-background;
  justify-content: center;

  form {
    width: 30%;
    margin: auto;
  }

  .sign-in-form {
    text-align: center;
    background-color: $color-white;
    @include flex(center, center);
    padding: 48px 48px 72px 48px;
    flex-direction: column;

    .welcome-msg {
      @include font-style($font-main, 700, 36px, 43px);
    }

    .desc {
      @include font-style($font-main, 400, 20px, 20px);
      padding-top: 23px;
      color: $color-black;
    }
  }
}

.sign-in-form .MuiTextField-root {
  width: 100%;
}

.error-message {
  width: 93%;
  color: red;
  font-weight: bold;
  background: #FCEBEB;
}

.sign-in-form .MuiButton-contained {
  @include button;
  border-radius: 4px;
  width: 100%;
  padding: 13px 0;
}

.sign-in-option {
  @include flex(space-between, center);
  width: 100%;
  padding: 24px 0;

  .forgot-pass {
    @include font-style($font-main, 400, 16px, 16px);
    color: $color-black;
    cursor: pointer;
  }

  .MuiTypography-root {
    @include font-style($font-main, 400, 16px, 16px);
    color: $color-black;
  }

  .forgot-pass:hover {
    color: #425df9;
  }
}

.no-account {
  @include font-style($font-main, 400, 16px, 16px);
}

.no-account span {
  color: #5771f9;
  cursor: pointer;
}

@media (min-width: 1024px) and (max-width: 1768px) {

  .sign-in-main {
    form {
      width: 40%;
      margin: auto;
    }

    .sign-in-form {
      padding: 36px 48px 64px 48px;

      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 20px);
        padding-top: 10px;
      }
    }
  }

  .sign-in-option {
    .MuiTypography-root {
      @include font-style($font-main, 400, 16px, 16px);
      color: $color-black;
    }

    .forgot-pass {
      @include font-style($font-main, 400, 16px, 16px);
      color: $color-black;
    }
  }
}

//Tablet Responsive

@media (min-width: 768px) and (max-width: 1023px) {
  .sign-in-main {
    width: 100%;

    form {
      width: 100%;
    }
  }

  .sign-in-form {
    margin: auto;
    width: 55%;
    padding: 48px 48px 72px 48px !important;
  }

  .sign-in-option {
    width: 100%;
  }

  .sign-in-form .MuiTextField-root {
    width: 100%;
  }

  .sign-in-form .MuiButton-contained {
    width: 100%;
  }
}

//Mobile Responsive
@media (max-width: 767px) {
  .sign-in-main form {
    width: 90% !important
  }

  .sign-in-main {
    .sign-in-form {
      padding: 48px 25px 64px 25px !important;

      .desc {
        @include font-style($font-main, 400, 18px, 28px);
        // padding-top: 23px;
        color: $color-black;
      }
    }
  }

  .sign-in-option {
    width: 100%;
  }

  .sign-in-form .MuiTextField-root {
    width: 100%;
  }

  .sign-in-form .MuiButton-contained {
    width: 100%;
  }
}