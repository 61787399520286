$media: (
        desktopXl:1680px,
        desktopMd:1280px,
        desktopSm:1024px,
        mobileXl:769px,
        mobileMd:640px
);

@mixin desktopXl {
  @media (min-width: map-get($media,desktopXl)) {
    @content
  }
}
@mixin desktopMd {
  @media (min-width: map-get($media,desktopMd)) {
    @content
  }
}
@mixin desktopSm {
  @media (min-width: map-get($media,desktopSm)) {
    @content
  }
}
@mixin mobileXl {
  @media (min-width: map-get($media,mobileXl)) {
    @content
  }
}
@mixin mobileMd {
  @media (min-width: map-get($media,mobileMd)) {
    @content
  }
}
@mixin mobileSm {
  @media (max-width: map-get($media,mobileMd)) {
    @content
  }
}
@mixin hyphens {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}