.polls {
  margin: 100px auto 0 auto;
  width: 98%;
  border: 1px solid #000000;
  min-height: 510px;

  &__resize-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;

    &--left {
      position: relative;
      padding: 30px;
      border-right: 1px solid #000000;
      background-color: #ff00ff;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--right {
      position: relative;
      padding: 30px;

      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  &__radio-group {
    padding: 10px;
    border-radius: 16px;
    background-color: #f3f4f8;
  }
}

  .text-white {
    color: white;
  }