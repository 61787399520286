@import '../../App';

.poster-wrap {
  display: flex;
  flex-direction: column;
  background-color: $bgColor-event;
  height: 100%;

  .posters-main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 90%;
    overflow: auto;

    .search {

      justify-content: space-around;
    }

    .poster-card {
      cursor: pointer;
      width: 90%;
      box-sizing: border-box;
      padding: 20px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      background-color: $bgColor-posters;

      .short_description-posters {
        @include font-style($font-third, 400, 14px, 25px);
        color: $color-message-color;
        @include more-text(2);
      }
      .navigate-posters-details-active,
      .navigate-posters-details {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          @include font-style($font-title, 600, 20px, 28px);
        }
      }

      .navigate-posters-details{
        h4{
          &:after {
            content: '';
            display: block;
            width: 110px;
            height: 1px;
            background: rgba(40, 40, 40, .08);
            border-radius: 3.5px;
          }
        }
      }
      .navigate-posters-details-active{
        h4 {
          &:after {
            content: '';
            display: block;
            width: 110px;
            height: 1px;
            background: $bgColor-navigate-posters-details-active;
            border-radius: 3.5px;
          }
        }
      }

      .poster-desc {
        border: 1px solid #000;
        padding: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        @include font-style($font-third, 400, 14px, 25px);

      }

      .poster-authors {
        line-height: 20px;

        .poster-authors-p {
          font-size: 14px;
          @include font-style($font-third, 600, 14px, 24px);
        }
      }

      .poster-video {
        @include flex("center", "center");

        & > a {
          @include flex("center", "center");
          text-decoration: none;
          line-height: 30px;
          cursor: pointer;

          & > span {
            padding-right: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width:mediaWight(desktopSm)) {
  .poster-wrap {
    .posters-main{
     height: 70vh;
      margin-bottom: 25px;
      overflow: scroll;
    }
    .posters-main-desc-mobile {
      flex-direction: row;
      height: 40%;

      .poster-card {
        min-width: 400px;
        min-height: 150px;
        height: auto;
      }
    }
  }
}