@import "../../App";
@import "../../Colors";

.live-poll {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  //background: rgba(0, 0, 0, 0.36);


  .live-poll-card {
    background: $color-white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 20%), 0px 1px 1px 0px rgba(0, 0, 0, 14%), 0px 1px 3px 0px rgba(0, 0, 0, 12%);
    padding: 16px;
    margin: 20px 15px;
    border-radius: 10px;
    width: 60%;
    max-width: 700px;

    .live-poll-question {
      @include font-style($font-main, fontWeight('extraBold'), 18px, 120%);
    }

    .live-poll-head {
      @include flex(space-between, center);
      @include font-style($font-main, fontWeight('extraBold'), 18px, 120%);
      padding: 10px 0;


      .poll-title {
        @include font-style($font-title, fontWeight(bold), 22px, 27px);
      }

      .end-side {
        display: flex;
        gap: 8px;
        align-items: center;

        .poll-ends-in {
          @include font-style($font-main, 400, 12px, 12px)
        }

        svg:hover {
          transform: scale(1.2);
          cursor: pointer;
          transition: all 500ms;
        }
      }

      .ends-timer {
        padding: 4px 8px;
        @include font-style($font-main, 600, 12px, 12px);
        border-radius: 20px;
        background: #4C6FFF29;
        color: $color-blue;
      }
    }
  }
}

@media (min-width: 1921px) and (max-width: 2560px) {
  .live-poll-card {
    width: 90% !important;
  }
}


//all mobiles
@media (max-width: 767px) {
  .live-poll {
    width: 100% !important;

    .live-poll-card {
      width: 100%;
    }
  }
}


//small laptops
@media (min-width: 768px) and (max-width: 1023px) {
  .live-poll {
    width: 100% !important;
    //height: 54.5vh !important;

    .live-poll-card {
      width: 100%;
    }
  }
}