@import "../../../App";

.participant-profile-main {
  display: flex;
  height: 100%;
  max-height: 90.85vh;
  background-color: $color-white;
  padding-bottom: 64px;
}

.participant-profile-inside {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $color-white;
}

.participants-title-main-profile {
  @include flex(space-between, center);
  padding: 24px 20px;
  //background: $card_background_1;
  //color: $color-background;
  background-color: $child-titleColor;
  color: $child-titleTextColor;
  height: $wight-title-layout-children;
  min-height: 76px;
  box-sizing: border-box;
}

.participant-profile-detail {
  display: flex;
  padding: 24px 36px 0 36px;
  flex-direction: column;
  align-items: center;

  .user-name {
    padding-top: 16px;
    @include font-style($font-main, 600, 20px, 23px);
  }

  .user-email {
    padding-top: 8px;
    @include font-style($font-main, 400, 16px, 22px);
    color: $color-message-color;
  }

  .user-desc {
    padding-top: 36px;
    @include font-style($font-main, 400, 16px, 28px);
    text-align: left;
    color: $color-message-color;
    white-space: break-spaces;
  }
}

.participant-address-detail {
  max-width: 100%;
  @include font-style($font-main, 400, 16px, 24px);
  padding: 36px 36px 48px 36px;

  div {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .participant-detail {
    p {
      width: 80%;
    }
  }


}

.participant-option {
  padding: 20px 36px;
  gap: 16px;
  @include flex(space-between, center);

  button {
    @include button;
  }

}

.participants-profile-img {
  border-radius: 50%;
}


@media (min-width: 1024px) and (max-width: 1680px) {

  .participants-title-main-profile {
    @include flex(space-between, center);
    padding: 12px 16px;
    background-color: $child-titleColor;
    color: $child-titleTextColor;
  }

  .participant-profile-detail {
    padding: 24px 24px 0 24px;

    .user-name {
      padding-top: 12px;
      @include font-style($font-main, 500, 16px, 20px);
    }

    .user-email {
      padding-top: 4px;
      @include font-style($font-main, 400, 14px, 16px);
    }

    .user-desc {
      padding-top: 0px;
      @include font-style($font-main, 400, 13px, 20px);
    }
  }

  .participant-address-detail {
    @include font-style($font-main, 400, 14px, 24px);
    padding: 24px 24px 16px 24px;

    div {
      display: flex;
      gap: 16px;
    }
  }

  .participant-option {
    padding: 24px 24px;
    gap: 16px;
    @include flex(space-between, center);

    button {
      @include button;
    }
  }
}

.mT2px {
  margin-top: 2px;
}