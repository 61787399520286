@import "../../App";

$text-color-black-half: rgba(40, 40, 40, 0.5);

.editing-profile-main-container {
  background: $color-white;

  .edit-profile-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 84px;
    gap: 8px;
    box-sizing: border-box;
    height: 135px;
    background: #F8F8F8;

    h1 {
      @include font-style($font-main, fontWeight('bold'), 32px, 39px);
    }

    .arrow-back {
      width: fit-content;
      @include flex(center, center);
      color: $text-color-black-half;
      gap: 8px;


      span {
        font-weight: fontWeight('light');
        font-size: 22px;
      }
    }
  }

  .edit-profile-wraper {
    width: 60%;
    margin: 52px auto 0;
    padding-bottom: 52px;

    .container-header {
      .container-introduce {
        width: fit-content;
        @include flex(start, center);
        gap: 24px;
        margin: 0 0 40px;

        p {
          @include font-style($font-main, 'bold', 24px, 29px);
        }

        span {
          @include font-style($font-main, 'light', 18px, 21px);
          color: $text-color-black-half;

        }
      }

      .editing-fields-container {
        margin: 40px 0;
        display: flex;
        justify-content: space-between;

        .field-tabs-container {
          p {
            @include font-style($font-main, 'light', 16px, 19px);
            color: $text-color-black-half;
          }
        }

        .add-avatar-container {
          width: fit-content;

          .avatar-action-container {
            margin: 56px 76px 56px 0;

            .add-avatar {
              width: 212px;
              height: 212px;
              border-radius: 50%;
              position: relative;
              overflow: hidden;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .avatar-action {
              @include flex(center, center);
              position: absolute;
              gap: 8px;
              bottom: 0;
              width: 100%;
              height: 40px;
              background:$text-color-black-half
            }
          }
        }

        .fields-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 24px;

          .first-last-name-container {
            @include flex(start, center);
            gap: 8px;
            margin: 16px 0;
          }

          .fields-2-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
          }
        }

        .biography-container {
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
        }
      }

      .change-password-editing-container {
        margin-top: 40px;

        .change-form-wrap {
          @include flex(center, center);
          display: flex;
          gap: 1%;
          justify-content: center;
          align-items: center;

        }

        .title-change-password {
          margin-bottom: 16px;

          h2 {
            @include font-style($font-main, 'bold', 24px, 29px);

          }

          span {
            @include font-style($font-main, 'light', 18px, 21px);
            margin-left: 30px;
            color:$text-color-black-half
          }
        }
      }
    }
  }
}

.change-password-btn-container {
  width: 100%;
  @include flex(end, center);
}

@media screen and (max-width:mediaWight(desktopSm)) {
  .editing-profile-main-container {
    .edit-profile-wraper {
      max-width: 1000px;

      .container-header {

        .container-introduce {
          flex-direction: column;
          align-items: flex-start;
          height: auto;
          gap: 10px;
          margin-bottom: 20px;

        }

        .editing-fields-container {
          flex-direction: column;

          .add-avatar-container {
            width: 100%;

            .avatar-action-container {
              display: flex;
              justify-content: center;
              margin: 0 0 20px;

              .add-avatar {

              }
            }
          }

          .biography-container {
            width: auto;
            margin: 16px 24px 16px 0;

          }
        }

        .change-password-editing-container {
          .title-change-password {
            h2 {
              display: flex;
              flex-direction: column;

              span {
                margin: 0;
              }
            }
          }

          .change-form-wrap {
            gap: 16px;
            flex-direction: column;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .editing-profile-main-container {
    .edit-profile-wraper {
      width: 90%;

      .container-header {
        .editing-fields-container {
          .biography-container {
            margin-right: 0;
          }

          .fields-container {
            margin-right: 0;
          }
        }
      }
    }

    .edit-profile-header {
      flex-direction: row;
      padding: 0;
      justify-content: space-around;
      align-items: center;

      .arrow-back{
        span{
          font-size: 16px;
          padding: 2px 0 0 4px;

        }
        svg{
          width: 16px;
          height: 16px;
        }
      }

      h1 {
        font-size: 20px;
      }
    }
  }
}
