@import '../../App';

.circularProgress{
  margin: 20% 50% !important;
}

.select-home-page {
  background-color: $color-black !important;
  border-radius: 6px;
  border: none;
  @include font-style($font-main, 400, 16px, 100%);
  min-width: 180px !important;
  color: white!important;

  svg{
    color: $color-white !important;
  }
}

.more-rounded-btn {
  background-color: $color-black;
  border-radius: 9999px;
  padding: 9px 9px;
  margin-left: -25px;
  color: $color-white;
  z-index: 1;

  p {
    margin: auto;
  }
}

@media (max-width: 768px) {
  .form-control-home-page {
    width: 100% !important;
  }
}

.speakers-overlay-main {

  position: relative;

  img:hover~.speakers-overlay {
    opacity: 1;
  }

  .speakers-overlay {
    position: absolute;
    top: 40px;
    left: 0;
    width: 250px;
    height: 200px;
    border-radius: 8px;
    background: $color-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    padding: 20px;
    opacity: 0;

    .speaker-title {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;

      .speaker-name {
        .name {
          @include font-style($font-second, 500, 16px, 18.75px)
        }

        .type {
          @include font-style($font-third, 400, 14px, 14px);
          color: $color-black;
        }
      }
    }

    .speaker-bio {
      p {
        @include font-style($font-third, 400, 12px, 21px);
        color: $color-black;
      }
    }
  }

  .speaker-bio {
    height: 100px;
    padding: 10px;
    overflow-y: hidden;
    box-sizing: border-box;
  }

  .speakers-links {
    height: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }
}