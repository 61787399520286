@import "./App.scss";
//@import url('https://fonts.googleapis.com/css2?family=Poppins');
//@import "../src/fonts/fellix/stylesheet.scss";
//@import "../src/fonts/helvetica/stylesheet.scss";

// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto|Open+Sans|Inter:400italic,700italic,400,700);

// Font Variables



.delete-popover{
    .MuiPopover-paper{
        box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1) !important;
    }
}
* {
    font-family: $font-main ;
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;
}

body {
    overflow: overlay;
    background-color: $color_one;
    color: $color-black;
}

/* Add a thumb */
*::-webkit-scrollbar-thumb {
    width: 5px;
    background: #9598a4;
    border-radius: 99px;
}

.participants-cards-main::-webkit-scrollbar-thumb,.question-cards-main::-webkit-scrollbar-thumb,
.event-agenda-cards-main::-webkit-scrollbar-thumb, .live-polls-container::-webkit-scrollbar-thumb,
.event-detail-inside::-webkit-scrollbar-thumb {
    background: rgb(213, 215, 221);
}

::-webkit-scrollbar {
    width: 5px;
    height: 100%;
    background-color: transparent;
    /* or add it to the track */
}

.font-color-white{
    min-width: 180px;
    color: $color-white !important;
}