@import "../../App";

.banner {
  width: 100%;
  padding: 14px 25px;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  gap: 15px;

  font-size: 14px;
  color: $color_one;
  background-color: $color_three;
  box-sizing: border-box;

  .MuiSvgIcon-root {
    color: $color_one !important;
  }

  & > div {
    white-space: nowrap;

    border-radius: 18px;
    padding: 7px 35px 5px;
    display: inline-block;
    font-family: $font-main;
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    cursor: pointer;
  }

  & > #form-control {
    z-index: 101;
    padding-left: 0;
    border: none;
  }

  &__select {
    color: #fff;
    border: 1px solid #fff;

    &::before {
      content: url("../../../public/planet-earth.svg");
      position: relative;
      top: 2px;
    }
  }
}